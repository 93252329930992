<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  background: #10151e;
  color: #eaedff;
  // background-image: url('./assets/images/background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
* {
  padding: 0;
  margin: 0;
}
ul,
li {
  list-style: none;
}
i {
  font-style: normal;
}
// 滚动条设置
::-webkit-scrollbar {
  width: 0;
}
::-webkit-scrollbar-track {
  width: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #585c61;
}
::-webkit-scrollbar-thumb:hover {
  width: 0;
}
::-webkit-scrollbar-thumb:active {
  border-radius: 10px;
  background: #585c61;
}
</style>
