<template>
  <div class="wrapper">
    <span class="weekday">{{ weekDay }}</span>
    <span class="weekday">{{ apm }}</span>
    <span class="lasttime">{{ lastTime }}</span>
    <span class="username">{{ logoConfig.userName }}</span>
    <!-- <a-avatar clas="avatar" :src="userImg"></a-avatar> -->
    <a class="signout" @click="signOut">退出</a>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, } from "vue";
// import { DownOutlined } from '@ant-design/icons-vue';
import dayjs from "dayjs";
import router from "@/router/index.js";
export default {
  // components: {
  //   DownOutlined,
  // },
  props: ["logoConfig"],
  setup () {
    //获取用户基本信息  未登录跳到登录页

    const userImg = ref();
    const userName = ref();
    const weekDay = ref();
    const apm = ref();
    const lastTime = ref();
    window.QHPass.getUserInfo(
      function (userInfo) {
        const { img_url, username } = userInfo;
        userImg.value = img_url;
        if (username == 'wentiandemo3') {
          userName.value = '国家金融科技风险监控中心';
        }
        if (username == 'wentiandemo') {
          userName.value = '加固保saas用户';
        }
      },
      function () {
        router.push("/");
      }
    );

    //时间显示
    const weeks = [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六"
    ];
    const apmArr = ["上午", "下午"];
    const formatTime = () => {
      let newData = dayjs();
      let hh = newData.format("HH");
      let mm = newData.format("mm");
      let w = newData.day();
      let ind = hh - 12 < 0 ? 0 : 1;
      if (ind == 1) {
        hh -= 12;
      }
      apm.value = apmArr[ind];
      weekDay.value = weeks[w];
      lastTime.value = hh + ":" + mm;
      return [weekDay, apm, lastTime];
    };
    let timer = null;
    onMounted(() => {
      timer = setInterval(() => {
        formatTime();
      }, 1000);
    });
    onBeforeUnmount(() => {
      clearInterval(timer);
    });
    let newTime = formatTime();

    //退出登录
    const signOut = () => {
      localStorage.removeItem('searchDate');
      window.QHPass.signOut(function () {
        router.push("/");
      });
    };
    return {
      newTime,
      signOut,
      formatTime,
      userImg,
      userName,
      weekDay,
      apm,
      lastTime
    };
  }
};
</script>

<style scoped lang="less">
// .wrapper{
//   display: flex;
// }
.weekday {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-right: 10px;
  color: #eaedff;
}
.lasttime {
  font-family: 'Azonix';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 26px;
  color: #eaedff;
  margin-right: 32px;
}
.username {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #9fa2b1;
  margin-right: 8px;
}
.avatar {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.signout {
  text-decoration: none;
  color: #eaedff;
}
</style>