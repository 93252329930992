<template>
  <div class="nav">
    <div class="nav-main">
      <!-- 0标准型，1特殊型 -->
      <div v-if="logoConfig.type === '0'" class="nav-logo">
        <img :src="logoConfig.logo" v-if="logoConfig.logo" />
        <img src="@/assets/images/nav/logo.png" v-else />
      </div>
      <div v-if="logoConfig.type === '1'" class="nav-logo-box">
        <img :src="logoConfig.logo" v-if="logoConfig.logo" />
        <div class="nav-logo-text">{{ logoConfig.name || '' }}</div>
      </div>
      <div class="nav-menu">
        <div
          v-for="(item, index) in menus"
          :class="activeState === item.path ? 'active' : ''"
          :key="index"
          @click="onClick(item.path)"
        >
          <a-dropdown overlayClassName="navMenu">
            <div>
              <span class="nav-menu-icon">
                <img
                  :src="activeState === item.path ? item.activeIcon : item.icon"
                />
              </span>
              <span>{{ item.name }}</span>
            </div>
            <template #overlay v-if="item.children && item.children.length > 0">
              <a-menu>
                <a-menu-item
                  v-for="(data, index) in item.children"
                  :key="index"
                >
                  <div @click="onClickTo(data.path)">{{ data.name }}</div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <div class="nav-user">
        <User :logoConfig="logoConfig" />
      </div>
    </div>
    <div class="nav-line">
      <video
        :src="getImgPath('line01.webm')"
        autoPlay="autoPlay"
        loop="loop"
        muted="muted"
      ></video>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import router from '@/router/index.js';
import User from '../../modules/user/index.vue';
import { getLogoConfig } from "@/api/nav.js";
import { getImgPath } from '@/utils/addColor.js';
export default {
  name: 'HomePage',
  components: {
    User
  },
  setup () {
    const logoConfig = ref({});
    const menus = [{
      name: '首页',
      activeIcon: getImgPath("nav/home.png"),
      icon: getImgPath("nav/home1.png"),
      path: '/home'
    }, {
      name: '应用监测',
      activeIcon: getImgPath("nav/appMonitor.png"),
      icon: getImgPath("nav/appMonitor1.png"),
      path: '/application'
    }, {
      name: '应用安全',
      activeIcon: getImgPath("nav/appSafe.png"),
      icon: getImgPath("nav/appSafe1.png"),
      path: '/appSafe'
    }, {
      name: '应用合规',
      activeIcon: getImgPath("nav/crashLog.png"),
      icon: getImgPath("nav/crashLog1.png"),
      path: '/appCompliance'
    },
    {
      name: '运营监测',
      activeIcon: getImgPath("nav/data.png"),
      icon: getImgPath("nav/data1.png"),
      path: '/dataAnalysis'
    },
    {
      name: '仿冒监测',
      activeIcon: getImgPath("nav/piracy.png"),
      icon: getImgPath("nav/piracy1.png"),
      path: '/pirate'
    },
    {
      name: '环境监测',
      activeIcon: getImgPath("nav/envMonitor.png"),
      icon: getImgPath("nav/envMonitor1.png"),
      path: '/environment'
    },
    {
      name: '风险管控',
      activeIcon: getImgPath("nav/riskControl.png"),
      icon: getImgPath("nav/riskControl1.png"),
      path: '/riskControl'
    }, {
      name: '资产管理',
      activeIcon: getImgPath("nav/asset.png"),
      icon: getImgPath("nav/asset1.png"),
      path: '/safemonitor/scan/list',
      children: [
        {
          name: '安卓应用安全检测',
          path: '/safemonitor/scan/list',
        }, {
          name: '安卓隐私合规安全检测',
          path: '/safemonitor/privacyScan/list',
        }, {
          name: 'IOS应用安全检测',
          path: '/safemonitor/iosScan/list',
        }, {
          name: 'IOS隐私合规检测',
          path: '/safemonitor/privacyIosScan/list',
        }, {
          name: '小程序安全监测',
          path: '/safemonitor/detection/list',
        }
      ]
    }];


    const activeState = ref(location.hash.slice(1));

    const onClick = (value) => {
      activeState.value = value;
      router.push(value);
    };
    const onClickTo = (value) => {
      window.location.href = `/#${value}`;
    };
    const getLogoConfigData = async () => {
      const { data } = await getLogoConfig();
      logoConfig.value = data;
      var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = data.icon;  //icon图标
      document.getElementsByTagName("head")[0].appendChild(link);
    };

    getLogoConfigData();
    return { menus, activeState, logoConfig, onClick, onClickTo, getImgPath };
  },
}
</script>

<style scoped lang="less">
@import url('./index.less');
@import url('../../style/a-dropdown.less');
</style>